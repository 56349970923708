// Diff Expander
//
// Expand Diff hunk context lines.
//
// Types of expanders
//
// * Top hunk expander: On the first hunk line. Always expands upwards.
// * Middle hunk expander: In between hunks. Still expands upwards but has
//     previous and next lines.
// * Bottom expander: No hunk line. Expands downwards.
//

// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
import type {IncludeFragmentElement} from '@github/include-fragment-element'
import {scrollIntoView as _scrollIntoView} from '../sticky-scroll-into-view'
import {ensureExpanded} from '../behaviors/details'
import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import {findElementByFragmentName} from '../fragment-target'
import hashChange from '../behaviors/hash-change'
import {matchHash} from './helpers'
import {parseHTML} from '@github-ui/parse-html'
import {preservePosition} from 'scroll-anchoring'
import {collapseAllInContainer, expandAllInContainer, supportsRichExpanding} from '../behaviors/render-editor'

function loadDiff(entryContainer: Element): Promise<string> {
  const fragment = entryContainer.querySelector<IncludeFragmentElement>('.js-diff-entry-loader')!
  const placeholder = entryContainer.querySelector<Element>('.js-diff-placeholder')!
  const button = entryContainer.querySelector<HTMLButtonElement>('button.js-diff-load')!
  const buttonText = entryContainer.querySelector<HTMLElement>('.js-button-text')!

  // show loader and disable button
  placeholder.setAttribute('fill', "url('#animated-diff-gradient')")
  buttonText.textContent = button.getAttribute('data-disable-with') || ''
  button.disabled = true

  const url = new URL(fragment.getAttribute('data-fragment-url') || '', window.location.origin)
  fragment.src = url.toString()

  return fragment.data
}

function scrollIntoView(element: Element) {
  ensureExpanded(element)
  _scrollIntoView(element)
}

interface Expansion {
  anchor: string
  side: string
  line: string
  lastLine?: string
  hashFragment: string
  partialHashFragment: string
}

hashChange(function handleHashChange() {
  const expansion = parseExpansion(window.location.hash)
  if (!expansion) return

  // The line exists and no range was provided, so return early.
  if (findElementByFragmentName(document, expansion.hashFragment) && !expansion.lastLine) {
    return
  }

  findAndActivateExpander(expansion, 0)
})

function parseExpansion(hash?: string): Expansion | undefined {
  if (!hash) return

  const match = matchHash(hash)
  if (!match) return

  const anchor = match[1]!
  const side = match[2]!
  const line = match[3]!
  const lastLine = match[5]
  const hashFragment = anchor + side + line
  const partialHashFragment = anchor + side
  return {anchor, side, line, lastLine, hashFragment, partialHashFragment}
}

async function findAndActivateExpander(expansion: Expansion, retries: number) {
  const {anchor, side, line, lastLine, hashFragment, partialHashFragment} = expansion

  const file = findElementByFragmentName(document, anchor)
  if (!file) return

  const expanders = findExpanders(file, side, line, lastLine)
  // The range has no expanders, so the range may be fully loaded,
  // or it may be a progressive diff.
  if (!expanders.length) {
    const element = findElementByFragmentName(document, hashFragment)
    if (element) {
      scrollIntoView(element)
      return
    }

    // No element was found, so let's try to load the diff.
    const loader = file.querySelector('.js-diff-load-container')
    if (!loader) return
    try {
      await loadDiff(loader)
      const el = findElementByFragmentName(document, hashFragment)
      if (el instanceof HTMLElement) {
        scrollIntoView(el)
      }
    } catch (e) {
      // An error occurred so scroll to the progressive diff.
      scrollIntoView(file)
    }

    return
  }

  await activateExpanders(expanders, partialHashFragment)

  const maxRetries = 1
  const element = findElementByFragmentName(document, hashFragment)
  const expanders2 = findExpanders(file, side, line, lastLine)
  if (expanders2.length) {
    // If a range was requested and there are more expanders, keep expanding.
    findAndActivateExpander(expansion, retries)
  } else if (element) {
    // Otherwise we've found the element to scroll to and expanded all its elements
    scrollIntoView(element)
  } else if (retries < maxRetries) {
    findAndActivateExpander(expansion, retries + 1)
  }
}

on('click', '.js-expand', function (event) {
  event.preventDefault()
  activateExpander(event.currentTarget as HTMLAnchorElement)
})

function activateExpanders(expanders: HTMLAnchorElement[], hashFragment: string): Promise<unknown> {
  return Promise.all(expanders.map(expander => activateExpander(expander, hashFragment)))
}

async function activateExpander(expander: HTMLAnchorElement, partialHashFragment?: string): Promise<void> {
  let anchor
  if (partialHashFragment) {
    const attrName = partialHashFragment.slice(-1) === 'R' ? 'data-right-range' : 'data-left-range'
    const range = expander.getAttribute(attrName) || ''
    const start = parseInt(range.split('-')[0]!, 10)
    anchor = partialHashFragment + start
  } else {
    anchor = expander.hash.slice(1)
  }

  const baseURL = expander.getAttribute('data-url')!
  const url = new URL(baseURL, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.append('anchor', anchor)
  url.search = params.toString()

  const data = await fetchSafeDocumentFragment(document, url.toString())

  const container = expander.closest<HTMLElement>('.js-file')
  if (!container) {
    return
  }

  const expandableLine = expander.closest<HTMLElement>('.js-expandable-line')!
  const nextLine = next(expandableLine, '.file-diff-line')
  if (nextLine) {
    preservePosition(nextLine, () => {
      expandableLine.replaceWith(data)
    })
  } else {
    expandableLine.replaceWith(data)
  }

  fire(container, 'expander:expanded')

  // Remove the expand-all button if there are no remaining
  // directional expanders
  removeExpandAllButton(container)
}

function next(el: Element, selector: string): HTMLElement | null {
  const sibling = el.nextElementSibling
  return sibling instanceof HTMLElement && sibling.matches(selector) ? sibling : null
}

// Find js-expand class that matches line number range.
//
// js-expand Elements are annotated with left and right ranges of line numbers
// they can expand to.
//
//   <a class="js-expand" data-left-range="8-21" data-right-range="9-20">
//
// Where "8-21" is an inclusive range.
//
//   findExpander(file, 'L', '20')
//
// container - .file Element
// side      - 'L' or 'R' String
// line      - Line number String
function findExpanders(
  container: Element,
  side: string,
  line: string,
  lastLine: string | undefined,
): HTMLAnchorElement[] {
  const lineNum = parseInt(line, 10)
  const lastLineNum = parseInt(lastLine || '', 10)
  return Array.from(container.querySelectorAll<HTMLAnchorElement>('.js-expand')).filter(el => {
    const attrName = side === 'R' ? 'data-right-range' : 'data-left-range'
    const range = (el.getAttribute(attrName) || '').split('-')
    const start = parseInt(range[0]!, 10)
    const end = parseInt(range[1]!, 10)
    if (start <= lineNum && lineNum <= end) {
      return true
    } else if (lineNum <= start && end <= lastLineNum) {
      return true
    } else if (start <= lastLineNum && lastLineNum <= end) {
      return true
    }
    return false
  })
}

interface ExpanderRange {
  left: {
    start: string
    end: string
    size: string
  }
  right: {
    start: string
    end: string
    size: string
  }
  position: string
}

interface DocumentFragments {
  content: DocumentFragment
  position: string
}

interface DocumentFragmentResponse {
  content: string
  position: string
}

function removeExpandAllButton(container: Element) {
  const expandFullWrapper = container.querySelector<HTMLButtonElement>('.js-expand-full-wrapper')
  if (!expandFullWrapper) return

  const expanders = Array.from(container.querySelectorAll<HTMLAnchorElement>('.js-expand'))
  if (expanders.length === 0) {
    expandFullWrapper.parentElement!.removeChild(expandFullWrapper)
  }
}

async function fetchManySafeDocumentFragments(document: Document, url: RequestInfo): Promise<DocumentFragments[]> {
  const response = await self.fetch(url, {
    headers: {'Content-Type': 'application/json', Accept: 'application/json'},
  })

  if (!response.ok) {
    throw new Error(`Request to blob_expand failed with status code ${response.status}`)
  }

  const fragments = (await response.json()) as DocumentFragmentResponse[]
  return fragments.map(fragment => ({
    ...fragment,
    content: parseHTML(document, fragment.content),
  }))
}

function maybeHandleRichExpanding(
  container: Element,
  action: typeof expandAllInContainer | typeof collapseAllInContainer,
) {
  if (container instanceof HTMLElement && supportsRichExpanding(container)) {
    return action(container)
  }
  return false
}

on('click', '.js-expand-full', async evt => {
  evt.preventDefault()
  const target = evt.currentTarget
  const container = target.closest('.file')!
  const baseURL = target.getAttribute('data-url')!
  const ranges = getAllHunkRanges(container)

  function expandUI() {
    // Remove the button now that we've expanded the full file
    target.setAttribute('hidden', 'true')

    // Show the collapse button
    const collapseBtn = container.querySelector('.js-collapse-diff')
    if (collapseBtn) collapseBtn.removeAttribute('hidden')

    // Ensure the file is "open"
    container.classList.add('open')
    container.classList.add('Details--on')
  }

  if (maybeHandleRichExpanding(container, expandAllInContainer)) return void expandUI()

  // If there are no ranges to fetch, we shouldn't bother. In this case, the UI
  // shouldn't have even rendered the button in the first place.
  if (ranges.length === 0) return

  const url = new URL(baseURL, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))

  for (const range of ranges) {
    params.append('ranges[]last_left', range.left.start)
    params.append('ranges[]left', range.left.end)
    params.append('ranges[]left_hunk_size', range.left.size)
    params.append('ranges[]last_right', range.right.start)
    params.append('ranges[]right', range.right.end)
    params.append('ranges[]right_hunk_size', range.right.size)

    // Attach the position of the relevant line, to replace the
    // right element after we've received the fragments
    params.append('ranges[]position', range.position)
  }

  url.search = params.toString()

  const fragments = await fetchManySafeDocumentFragments(document, url.toString())
  const expanders = [...container.querySelectorAll('.js-expand')]

  for (const {content, position} of fragments) {
    // Use the index of the original expander that we're replacing
    const index = parseInt(position, 10)
    const expander = expanders[index]
    if (!expander) return

    const expandableLine = expander.closest<HTMLElement>('.js-expandable-line')!
    const nextLine = next(expandableLine, '.file-diff-line')
    if (nextLine) {
      preservePosition(nextLine, () => {
        expandableLine.after(content)
      })
    } else {
      expandableLine.after(content)
    }

    // Hide the line in case we want to show it again after
    expandableLine.setAttribute('hidden', 'true')
  }

  expandUI()
})

on('click', '.js-collapse-diff', evt => {
  const target = evt.currentTarget
  const container = target.closest('.file')!

  function collapseUI() {
    // Show the expand button, hide this one
    target.setAttribute('hidden', 'true')
    const expandBtn = container.querySelector('.js-expand-full')
    if (expandBtn) expandBtn.removeAttribute('hidden')
  }

  if (maybeHandleRichExpanding(container, collapseAllInContainer)) return void collapseUI()

  // Remove all the expanded lines
  const expandedLines = [...container.querySelectorAll('.blob-expanded[data-expanded-full="true"]')]
  for (const el of expandedLines) {
    el.parentElement!.removeChild(el)
  }

  // Unhide the diff header lines that show expanders
  const expandableLines = [...container.querySelectorAll('.js-expandable-line')]
  for (const el of expandableLines) {
    el.removeAttribute('hidden')
  }

  collapseUI()
})

function getExpanderRange(expander: HTMLAnchorElement, index: number): ExpanderRange {
  const url = new URL(expander.getAttribute('data-url')!, window.location.origin)
  const params = url.searchParams

  return {
    // Store the index of this expander in the list of expanders within
    // this container. This will be used after the request is made, to
    // know which DOM elements to replace with the new `.blob-expanded` lines
    position: index.toString(),
    left: {
      start: params.get('last_left')!,
      end: params.get('left')!,
      size: params.get('left_hunk_size')!,
    },
    right: {
      start: params.get('last_right')!,
      end: params.get('right')!,
      size: params.get('right_hunk_size')!,
    },
  }
}

function getAllHunkRanges(container: Element): ExpanderRange[] {
  const expanders = Array.from(container.querySelectorAll<HTMLAnchorElement>('.js-expand'))
  const allRanges = expanders.map((el, index) => getExpanderRange(el, index))

  let skipNextRange = false
  const ranges: ExpanderRange[] = []
  for (let i = 0; i < allRanges.length; i++) {
    // This range should be skipped because its the same
    // as the previous range
    if (skipNextRange) {
      skipNextRange = false
      continue
    }

    const range = allRanges[i]!
    const nextRange = allRanges[i + 1]

    // See if these two are duplicates. This will happen when two expanders
    // are in the same containing div, and clicking either one would expand the
    // same range.
    skipNextRange =
      !!nextRange &&
      range.left.start === nextRange.left.start &&
      range.left.end === nextRange.left.end &&
      range.right.start === nextRange.right.start &&
      range.right.end === nextRange.right.end

    ranges.push(range)
  }

  return ranges
}
