export class DiffTableSideProtection {
  selection: Selection
  anchorNode: Node | null

  constructor(selection: Selection) {
    this.selection = selection
    this.anchorNode = selection.anchorNode
  }

  range(): Range | null | undefined {
    let range

    if (this.selection?.rangeCount > 0) {
      range = this.selection.getRangeAt(0)
    }

    return range
  }

  canBeSideProtected(): boolean {
    try {
      return !!(this.hasSelectedText() && this.confinedTable())
    } catch (e) {
      // noop for errors such as permission denied in gecko browsers
      return false
    }
  }

  isSideProtected(): boolean {
    try {
      return !!this.confinedTable()?.getAttribute('data-lock-side-selection')
    } catch (e) {
      // noop for errors such as permission denied in gecko browsers
      return false
    }
  }

  hasSelectedText(): boolean {
    const range = this.range()
    if (!range) return false

    return range.toString().length > 0
  }

  clearSelectedText() {
    this.selection.removeAllRanges()
  }

  confinedTable(): Element | null | undefined {
    const commonAncestor = this.range()?.commonAncestorContainer?.parentElement

    if (commonAncestor?.matches('.diff-table')) {
      // selection is confined to the table itself
      return commonAncestor
    } else if (commonAncestor?.matches('.js-file-content')) {
      // selection is confined to the div containing the table
      const closestTable = commonAncestor?.querySelector('table.diff-table')
      return closestTable
    } else {
      // selection is confined to an element within the table
      const closestTable = commonAncestor?.closest('table.diff-table')
      return closestTable
    }
  }

  unprotectedSide(): string | null | undefined {
    let td

    if (this.anchorNode instanceof HTMLTableCellElement) {
      td = this.anchorNode.closest('td[data-split-side]')
    } else {
      td = this.anchorNode?.parentElement?.closest('td[data-split-side]')
    }

    return td?.getAttribute('data-split-side') // 'left', 'right'
  }

  protectedSide(): string {
    return this.unprotectedSide() === 'left' ? 'right' : 'left'
  }

  applySideProtection() {
    if (this.unprotectedSide()) {
      const table = this.confinedTable()
      const side = this.unprotectedSide()

      if (table && side) {
        table.setAttribute('data-lock-side-selection', side)
      }
    }
  }

  clearSideProtection() {
    const tables = document.querySelectorAll('table[data-lock-side-selection]')
    for (const table of tables) {
      // this will always be an html element based on the query selection
      const htmlTable = table as HTMLElement
      htmlTable.removeAttribute('data-lock-side-selection')
    }
  }

  filteredTableRows() {
    const outputNodes: Node[] = []

    for (let i = 0; i < this.selection.rangeCount; i++) {
      const range = this.selection.getRangeAt(i)
      const content = range.cloneContents()

      if (content.querySelectorAll('td').length) {
        // content is a table row, so remove any protected / unwanted cells
        const unwantedRowQuery = `td[data-split-side=${this.protectedSide()}], td.js-linkable-line-number, td.empty-cell, .inline-comments`
        const protectedElements = content.querySelectorAll(unwantedRowQuery)
        for (const el of protectedElements) {
          if (el.parentNode) {
            el.parentNode.removeChild(el)
          }
        }
        outputNodes.push(...content.children)
      } else {
        // content is a fragment, so transform it into a table row for consistency elsewhere
        const row = document.createElement('TR')
        const cell = document.createElement('TD')
        cell.append(...content.childNodes)
        row.append(cell)
        outputNodes.push(row)
      }
    }

    return outputNodes
  }

  contentToString() {
    const outputLines: string[] = []
    const table = document.createElement('table')

    // Temporarily append invisible table to the dom
    //
    // This is necessary because we care about the text of just visible elements
    // and `innerText` only knows about their visibility if attached to the dom.
    // Some browsers (like chrome) will not actually calculate the visibility of
    // elements unless it thinks it is shown, so marking this element as hidden
    // will not work.
    table.classList.add('invisible')
    table.append(...this.filteredTableRows())

    // Append table to the confiedTable so that visibility of inline comments
    // retain the user preference when copied.
    this.confinedTable()?.append(table)

    // Grab the `innerText` from the table
    //
    // We are purposely avoiding the use of `table.innerText` because some
    // browsers (like chrome) return additional and unexpected line breaks.
    // Instead, iterate over the top level elements and ask each one for it's `innerText`.
    const children = table.children
    for (let i = 0; i < children.length; i++) {
      const el = children[i] as HTMLElement

      /* eslint-disable-next-line github/no-innerText */
      const text = el.innerText

      if (text) {
        if (text === '\n') {
          // array will eventually be separated by new lines,
          // but avoid inadvertent double line breaks
          outputLines.push('')
        } else {
          outputLines.push(text)
        }
      }
    }

    // Remove the temporary table from DOM
    table.remove()

    return outputLines.join('\n')
  }
}
