import type {IncludeFragmentElement} from '@github/include-fragment-element'
import {ensureExpanded} from '../behaviors/details'
import {findElementByFragmentName} from '../fragment-target'
import {loadDeferredContent} from '../behaviors/include-fragment'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {scrollIntoView} from '../sticky-scroll-into-view'
import {announce} from '@github-ui/aria-live'

observe('.js-diff-progressive-container', function (container) {
  processProgressiveContainer(container)

  const loader = container.querySelector('.js-diff-progressive-loader')
  if (!loader) {
    return
  }

  loader.addEventListener('load', function () {
    processProgressiveContainer(container)
  })
})

function loadResolvedComments(container: Element, anchor: string): void {
  const commentContainers = container.querySelectorAll('.js-comment-container')

  for (const commentContainer of commentContainers) {
    const threadCommentIds = commentContainer.getAttribute('data-hidden-comment-ids')

    if (threadCommentIds) {
      const commentIds = threadCommentIds.split(',')
      const anchorCommentId = anchor.match(/\d+/g)?.[0]

      if (anchorCommentId && commentIds.includes(anchorCommentId)) {
        loadDeferredContent(commentContainer)
        break
      }
    }
  }
}

function processProgressiveContainer(container: Element): void {
  const anchor = urlAnchor()
  if (!anchor) {
    return
  }

  scrollToAnchor(container, anchor)

  const entryContainer = getEntryFromAnchor(container, anchor)
  if (!entryContainer) {
    return
  }

  scrollIntoView(entryContainer)
  loadDiff(entryContainer)
}

observe('.js-diff-load-container', function (container) {
  const loader = container.querySelector('.js-diff-entry-loader')
  if (!loader) {
    return
  }

  loader.addEventListener('load', function () {
    const fileContainer = container.closest<HTMLElement>('.js-file')!
    fileContainer.classList.remove('hide-file-notes-toggle')

    const loadDiffPathName = fileContainer.getAttribute('data-tagsearch-path')
    announce(`loaded diff for ${loadDiffPathName}`)

    const anchor = urlAnchor()
    if (!anchor) {
      return
    }

    scrollToAnchor(container, anchor)
  })
})

on('click', '.js-diff-load', function (event) {
  if ((event.target as HTMLElement).classList.contains('js-ignore-this')) {
    return
  }

  const entryContainer = event.currentTarget.closest<HTMLElement>('.js-diff-load-container')!
  loadDiff(entryContainer)
  entryContainer?.focus()
  announce('Loading...')
})

function urlAnchor(): string {
  return window.location.hash.slice(1)
}

function scrollToAnchor(container: Element, anchor: string): void {
  let target: Element | null

  // If anchor is a range, cut off the range characters
  // E.g. 'diff-2b939de295abbeb764a2b0de8fe0e690L5-R7'
  // to   'diff-2b939de295abbeb764a2b0de8fe0e690L5'
  if (anchor.match(/^diff-.+[LR]\d+-[LR]\d+/)) {
    target = findElementByFragmentName(container.ownerDocument, anchor.substring(0, anchor.indexOf('-', 5)))
  } else {
    target = findElementByFragmentName(container.ownerDocument, anchor)
  }

  if (target && container.contains(target)) {
    ensureExpanded(target)
    scrollIntoView(target)
  } else {
    loadResolvedComments(container, anchor)
  }
}

function getEntryFromAnchor(container: Element, anchor: string): Element | undefined {
  const entryContainer = getEntryFromDiffAnchor(container, anchor)
  if (entryContainer) {
    return entryContainer
  }

  return getEntryFromCommentAnchor(container, anchor)
}

function getEntryFromDiffAnchor(container: Element, anchor: string): Element | undefined {
  const match = /^(diff-[0-9a-f]{32})(?:[L|R]\d+)?$/.exec(anchor)
  if (!match) {
    return
  }

  const entryId = match[1]
  const entryAnchor = container.querySelector(`a[name='${entryId}']`)
  if (!entryAnchor) {
    return
  }

  const entryContainer = entryAnchor.nextElementSibling
  if (entryContainer && !entryContainer.querySelector('.js-diff-load-container')) {
    return
  }

  return entryContainer!
}

function getEntryFromCommentAnchor(container: Element, anchor: string): Element | undefined {
  const match = /^(?:r|commitcomment-)(\d+)$/.exec(anchor)
  if (!match) {
    return
  }

  const commentId = match[1]
  const commentDummy = container.querySelector(`#diff-with-comment-${commentId}`)

  if (!commentDummy) {
    return
  }
  const entryContainer = commentDummy.closest('.js-file')

  return entryContainer!
}

function loadDiff(entryContainer: Element): Promise<string> {
  const fragment = entryContainer.querySelector<IncludeFragmentElement>('.js-diff-entry-loader')!
  const placeholder = entryContainer.querySelector<Element>('.js-diff-placeholder')!
  const button = entryContainer.querySelector<HTMLButtonElement>('button.js-diff-load')!
  const buttonText = entryContainer.querySelector<HTMLElement>('.js-button-text')!

  // show loader and disable button
  placeholder.setAttribute('fill', "url('#animated-diff-gradient')")
  buttonText.textContent = button.getAttribute('data-disable-with') || ''
  button.disabled = true

  const url = new URL(fragment.getAttribute('data-fragment-url') || '', window.location.origin)
  fragment.src = url.toString()

  return fragment.data
}
