// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {updateContent, reload} from '@github-ui/updatable-content'
import {SOFT_NAV_STATE} from '@github-ui/soft-nav/states'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {fetchPoll} from '@github-ui/fetch-utils'
import verifySsoSession from '../sso'
import {toggleDetailsTarget} from '../behaviors/details'
import {TemplateInstance} from '@github/template-parts'
import {remoteForm} from '@github/remote-form'
import {sendEvent} from '@github-ui/hydro-analytics'
import {ssrSafeDocument} from '@github-ui/ssr-utils'
import {isFeatureEnabled} from '@github-ui/feature-flags'

function updateContentOnSoftNavigations() {
  for (const el of document.querySelectorAll<HTMLElement>('.js-pull-refresh-on-pjax')) {
    updateContent(el)
  }
}

document.addEventListener(SOFT_NAV_STATE.SUCCESS, updateContentOnSoftNavigations)

function showFlash(message: string, detailsElement: HTMLElement): void {
  const template = document.querySelector<HTMLTemplateElement>('template.js-flash-template')!
  template.after(new TemplateInstance(template, {className: 'flash-error', message}))

  detailsElement.removeAttribute('open')
}

on('click', '.js-change-base-form-button', async function (event) {
  event.preventDefault()

  // disable button and show loading spinner
  const button = event.currentTarget as HTMLButtonElement
  const spinner = button.querySelector('.js-change-base-spinner') as HTMLSpanElement
  button.disabled = true
  spinner.style.display = 'inline'

  await verifySsoSession()

  const details = (event.target as HTMLElement).closest<HTMLElement>('details')!
  try {
    const form = button.form as HTMLFormElement
    const response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
    })
    const responseData = await response.json()

    toggleDetailsTarget(document.querySelector('.js-pull-header-details')!, {force: false})
    if (!response.ok) {
      showFlash(responseData.error_message, details)
      return
    }

    let url = null
    if (responseData.job) {
      url = responseData.job.url
    } else {
      url = responseData.orchestration.url
    }
    const pollingData = await (await fetchPoll(url, {headers: {accept: 'application/json'}})).json()
    if (pollingData.orchestration) {
      if (pollingData.orchestration.error_message) {
        showFlash(pollingData.orchestration.error_message, details)
      } else {
        return reload()
      }
    } else if (pollingData.job.error_message) {
      showFlash(pollingData.job.error_message, details)
    } else {
      return reload()
    }
  } catch (err) {
    // eslint-disable-next-line i18n-text/no-en
    showFlash('An unknown error occured.', details)
  }
})

function showUpdateBranchError(message: string, form: HTMLElement, currentSha: string): void {
  const target = form.closest('.js-pull-merging')
  if (!target) return

  target.classList.add('is-error')

  const errorTextContainer = target.getElementsByClassName('merge-branch-description')[0] as HTMLElement
  errorTextContainer.textContent = message

  const expectedHeadOid = target.querySelector<HTMLInputElement>("input[name='expected_head_oid']")
  if (!expectedHeadOid) return
  expectedHeadOid.value = currentSha
}

remoteForm('.js-update-branch-async-button', async function (form, wants) {
  await verifySsoSession()
  try {
    const response = await wants.json()

    const pollingData = await (
      await fetchPoll(response.json.orchestration.url, {headers: {accept: 'application/json'}})
    ).json()
    if (pollingData.orchestration.error_message) {
      showUpdateBranchError(pollingData.orchestration.error_message, form, pollingData.orchestration.current_head_sha)
    } else {
      // TODO(dzader): this isn't a great experience should probably make it better
      if (form.getElementsByClassName('js-update-branch-type')[0]?.getAttribute('value') === 'rebase') {
        return reload()
      }
    }
  } catch (error) {
    // eslint-disable-next-line i18n-text/no-en
    showUpdateBranchError('An unknown error occured.', form, '')
  }
})

observe('.js-updating-pull-request-commits-count', {
  add(el) {
    const countText = el.textContent
    const updateableCounts = document.querySelectorAll('.js-updateable-pull-request-commits-count')
    for (const count of updateableCounts) {
      count.textContent = countText
    }
  },
})

function isReactMergeBox() {
  return !!ssrSafeDocument?.querySelector('react-partial[partial-name="mergebox-partial"]')
}

if (isFeatureEnabled('BENCHMARK_MERGEBOX')) {
  let startTime = 0
  let markMergeBoxLoadTime = false
  let abortController: AbortController
  let softNav = false

  // Attach handlers and prepare to mark MergeBox load whenever the show page is loaded.
  // This covers the scenario where a user soft navs away from then back to the overview page
  observe('.js-pull-discussion-timeline', {
    add() {
      markMergeBoxLoadTime = true
      abortController?.abort()
      abortController = new AbortController()

      const stop = () => abortController.abort()

      // If the user changes tab, we don't want to send the recorded metrics since it may send garbage data.
      document.addEventListener('visibilitychange', stop, {signal: abortController.signal})

      // If the stop event is triggered, we want to stop listening to DOM mutations.
      abortController.signal.addEventListener('abort', () => {
        markMergeBoxLoadTime = false
      })
    },
  })

  observe('#partial-pull-merging', {
    add() {
      if (!markMergeBoxLoadTime) return

      const partialLoadTime = performance.now() - startTime
      sendEvent('mergebox-loaded', {value: partialLoadTime, react: isReactMergeBox(), softNav})
      abortController.abort()
    },
  })

  document.addEventListener(SOFT_NAV_STATE.START, () => {
    // keep up to date with the last soft nav start time
    startTime = performance.now()
    softNav = true
  })
}
